import React, { useState } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Form from "../../components/Form"
import "./index.css"

import slide1 from "../../images/Slide_1.jpg"
import slide2 from "../../images/Slide_2.jpg"
import slide3 from "../../images/Slide_3.jpg"
import logo from "../../images/navbar-logo.svg"

const ServicesPage = () => {
  const [slide, setSlide ] = useState(0)
  const images = [
    {
      slide: slide1,
      title: "Residential",
      message: "We can enhance the beauty, value and durability of any property with services that include Kitchen Remodeling, Bathroom Renovation, Roofing, Basement Renovation and Home Painting. We are one of the leading Maryland home improvement contractors with a great reputation both in the community and in the home renovation industry. We can enhance the beauty, value and durability of any property with services that include Kitchen Remodeling, Bathroom Renovation, Roofing, Basement Renovation and Home Painting. We are one of the leading Maryland home improvement contractors with a great reputation both in the community and in the home renovation industry."
    },
    {
      slide: slide2,
      title: "Commercial",
      message: "If you have commercial roofing needs, put your trust in J&C Remodeling Inc. We are a local commercial roofing contractor that has served the greater Baltimore-Washington area for over 15 years. Offering a complete array of commercial roofing services."
    },
    {
      slide: slide3,
      title: "Apartment Maintenance",
      message: "Our goal is to provide your residents with high-quality homes. We takes pride in the quality of your apartment complex, working diligently to keep them well maintained and we believe that this consistent dedication guarantees the very best standard of quality living for your residents."
    },
  ]

  return (
    <Layout>
      <div className="services-page">
        <section>
          <div className="services-section-top">
            <h1>OUR SERVICES</h1>
          </div>
        </section>

        <section>
        <div className="services-section-mobile">
            <div className="services-section-mobile-bottom">
              <h2>Residential</h2>
              <p>
              Roof installation, tear off, skylights, repairs, leaks, gutters, windows, siding, chimney re-flashing, doors, decks, drywall, kitchen, bathrooms, and basements.
              </p>
              <h2>Commercial</h2>
              <p>
              All types of commercial roofing including but not limited to shingle roofing, TPO, EPDM, Duralast membrane new construction and limited maintenance and service on interior and exterior needs.
              </p>
              <h2>Apartment Maintenance</h2>
              <p>
              Roofs, windows, siding, interior repairs including leaks and drywall.
              </p>
            </div>
      </div>
          <div className="services-slider">
            <div className="services-slider-top">
              <div onClick={() => setSlide(0)} style={slide === 0 ? activeStyle : inactiveStyle} className="slide-select-button">
                <p>RESIDENTIAL</p>
              </div>
              <div onClick={() => setSlide(1)} style={slide === 1 ? activeStyle : inactiveStyle} className="slide-select-button">
                <p>COMMERCIAL</p>
              </div>
              <div onClick={() => setSlide(2)} style={slide === 2 ? activeStyle : inactiveStyle} className="slide-select-button">
                <p>APPARTMENT MAINTENANCE</p>
              </div>
            </div>
            <div className="services-slider-bottom">
              <img src={images[slide].slide} alt="Remodeling" />
            </div>
          </div>
          <div className="services-message">
            <h2>{images[slide].title}</h2>
            <p>{images[slide].message}</p>
          </div>
        </section>

        <section>
          <div className="form-section">
            <div className="form-section-bottom">
              <div className="form-section-bottom-left">
              <img src={logo} alt="company logo" />
                <h3>
                  Get started with us by filling out the form to request your{" "}
                  <span style={{ color: `#cb3100` }}>FREE ESTIMATE</span>, or
                  give us a call at{" "}
                  <span style={{ color: `#cb3100` }}>(443) 623-2111</span> and
                  we'll be in touch!
                </h3>
              </div>
              <div className="form-section-bottom-right">
                <Form />
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

const activeStyle = {
  background: `#ED6134`,
}

const inactiveStyle = {
  background: `linear-gradient(0deg, #cb3100, #ed6134)`,
  boxShadow: `4px 4px 10px rgba(0, 0, 0, 0.5)`,
  transition: `0.5s`
}

export default ServicesPage
